@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=The+Nautigal:wght@400;700&display=swap');

$mainBlue: #1631b5;
$mainGreen: #b3ff00;
$textBlack: #2f2f2f;
$backgroundGreen: #42cc0d;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul,
li,
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
ul {
  list-style-type: none;
}

div,
section,
main,
article {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  &:visited {
    color: inherit;
  }
  &.active {
    color: inherit;
  }
}

body {
  font-family: 'Montserrat', sans-serif;
}

.App {
  overflow: hidden;
}

main.page-container {
  padding: 60px 70px;
  @media screen and (max-width: 767.2px) {
    padding: 10px;
  }
}

section.section-container {
  padding: 30px 40px;
  @media screen and (max-width: 767.2px) {
    padding: 20px;
  }
}

.input-box {
  position: relative;
  padding: 10px;
  label {
    color: white;
    font-size: 17px;
    text-align: left;
    font-weight: 200;
  }
  input, select {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 3px;
    font-size: 18px;
    padding: 8px;
    color: #797979;
    font-weight: 300;
    outline: none;
  }
}

.button-box {
  padding: 20px 10px 0;
  button {
    background-color: #42cc0d;
    color: white;
    padding: 10px;
    width: 100%;
    border: none;
    border-radius: 3px;
  }
}

main {
  position: relative;

  &[data-page='contacts'] {
    position: relative;
    top: 80px;
    margin-bottom: 80px;
  }
  &[data-page='home'] {
    position: relative;
    .slider {
      position: relative;
      width: 100%;
      background-color: rgb(20 37 118 / 74%);
      color: white;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      img.background {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        &.main {
          opacity: 0.2;
          transform: scale(1.1) scaleX(-1);
          left: 0;
          object-fit: cover;
          z-index: 1;
        }
      }
      .center-content {
        position: relative;
        top: -36px;
        text-align: center;
        left: 0px;
        .main-slogan {
          font-size: 39px;
          text-align: center;
          z-index: 2;
        }
        .secondary-slogan {
          font-family: 'The Nautigal', cursive;
          font-size: 66px;
          position: relative;
          transform: rotate(-11deg);
          z-index: 2;
        }

        .engagement-button{
          text-align: center;
          a {
            position: relative;
            top: 20px;
            text-decoration: none;
            font-weight: 400;
            line-height: 2;
            display: inline-block;
            border-radius: 6px;
            color: #ffffff;
            font-size: 16px;
            margin: 0 20px;
            z-index: 1;
            padding: 7px 27px 7px 14px;
            // text-transform: uppercase;
            letter-spacing: 2px;
            border: 2px solid #42cc0d;
            color: white;
            transition: all 0.3s ease;
            &:hover {
              background-color: #42cc0d;
              border: 2px solid #42cc0d;
              a {
                color: white;
              }
            }
            &:visited {
              color: white;
            }
            img{
              width: 27px;
              position: relative;
              margin: 0 16px 0 11px;
              top: -1px;
            }
            span.button-text{
              position: relative;
              top: 1px;
            }
          }
        } 
    

        @media screen and (max-width: 767.2px) {
          .main-slogan {
            font-size: 36px;
            text-align: center;
            position: relative;
          }
          .secondary-slogan {
            font-family: 'The Nautigal', cursive;
            font-size: 51px;
            position: relative;
            transform: rotate(-11deg);
          }
        }
      }
    }
  }
  &[data-page='courses'] {
    top: 80px;
    margin-bottom: 80px;
  }
  &[data-page='services'] {
    top: 80px;
    margin-bottom: 80px;
  }
  &[data-page='course'] {
    top: 80px;
    margin-bottom: 80px;
    .course-header {
      padding: 10px 0 34px;
      font-size: 33px;
      color: $textBlack;
      font-weight: 600;
    }
    .course-image {
      text-align: center;
      img {
        height: 220px;
        width: 220px;
        border-left: 1px solid #2445e417;
        border-radius: 32px;
        box-shadow: 15px 43px 30px -5px lightgrey;
        padding: 29px;
        margin-top:50px;
        transform: skewY(10deg);
        perspective: 1000px;
        border-right: 8px solid #0d228a6e;
        transform-origin: 100% 50%;
        border-top: 7px solid #122caa42;
        opacity: 1;
      }
    }
    .course-subjects {
      .subject-tag {
        font-size: 13px;
        padding: 4px 10px;
        border: 1px solid #9e9e9e;
        color: #505050;
        display: inline-block;
        margin: 3px;
        border-radius: 11px;
      }
    }
    .course-description {
      font-size: 17px;
      color: #515151;
      font-weight: 400;
      text-align: left;
      text-indent: 14px;
      padding: 20px 0;
    }
    .course-duration {
      padding: 10px 0;
    }
    .course-program {
      ul {
        li {
          margin: 20px 0px;
          h5 {
            font-size: 16px;
            font-weight: 400;
            i {
              background-color: #41c80c;
              width: 22px;
              height: 22px;
              border-radius: 50%;
              color: white;
              text-align: center;
              line-height: 1;
              font-weight: 600;
              display: flex;
              justify-content: center;
              align-items: center;
              display: inline-flex;
              transform: scaleX(-1) rotate(-51deg);
              font-size: 16px;
              margin-right: 17px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 990px) {
    }
    @media screen and (max-width: 767.2px) {
      .course-image {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        img {
          opacity: 0.3;
        }
      }
    }
  }

  section.section-container {
    .section-header {
      padding: 5px 0 60px;
      h1,h2,h3 {
        color: #0e48bb;
        font-weight: 600;
        text-align: center;
        font-size:27px;
      }

      .green-dot-line {
        position: relative;
        border-bottom: 7px dotted $mainGreen;
        left: 0;
        height: 22px;
        z-index: 1;
        width: 75px;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
  section.training-section {
    padding: 30px;
    background-color: white;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    .course-card {
      position: relative;
      height: 469px;
      max-width: 400px;
      display: flex;
      padding: 20px;
      margin: 10px auto;
      border-radius: 6px;
      box-shadow: 0 16px 25px -1px lightgrey;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s ease;
      cursor: pointer;
      &:hover {
        box-shadow: 15px 16px 25px -1px lightgrey;
      }
      h2,h3 {
        font-size: 19px;
        padding: 28px 0 0;
        height: 60px;
        white-space: pre-line;
      }
      img {
        width: 100%;
        object-fit: contain;
        height: 100px;
      }
      .course-description {
        font-size: 14px;
        color: #515151;
        font-weight: 300;
        text-align: left;
        text-indent: 14px;
      }
      a.more-info-button {
        border: 2px solid $mainBlue;
        color: $mainBlue;
        font-size: 16px;
        width: 100%;
        border-radius: 6px;
        font-weight: 300;
        padding: 8px 0;
        text-decoration: none;
        transition: all 0.2s ease;
        &:hover{
          background-color: #1631b5;
          color: white;
        }
      }
    }
  }
  section.services-section {
    background-color: white;
    text-align: center;
    font-size: 32px;
    font-weight: 700;

    .service-card {
      position: relative;
      height: 200px;
      max-width: 400px;
      display: flex;
      padding: 20px;
      overflow: hidden;
      margin: 20px auto;
      border-radius: 6px;
      box-shadow: 0 16px 25px -1px lightgrey;
      flex-direction: column;
      justify-content: space-between;
      transition: all 0.4s ease;
      cursor: pointer;
      &:hover {
        box-shadow: 15px 16px 25px -1px lightgrey;
      }
      h2,h3 {
        font-size: 19px;
        padding: 28px 0 0;
        z-index: 1;
      }
      img {
        width: 200px;
        object-fit: cover;
        height: 201px;
        text-align: center;
        display: inline-block;
        margin: 0 auto;
        border-radius: 15px;
        opacity: 0.1;
        position: absolute;
        transform: scale(2.4);
        top: 0;
        left: 0;
      }
      .service-description {
        font-size: 13px;
        color: #515151;
        font-weight: 300;
        text-align: left;
        text-indent: 14px;
      }
      a.lets-talk-button {
        position: relative;
        background-color: transparent;
        color: #1631b5;
        font-size: 16px;
        width: 100%;
        border: none;
        border-radius: 6px;
        font-weight: 600;
        padding: 15px 0;
        text-decoration: none;
        transition: all 0.3s ease;
        img {
          height: 12px;
          width: 12px;
          margin-right: 18px;
          z-index: 1;
          opacity: 1;
          display: inline-block;
          position: relative;
          top: -2px;
        }
        &:hover {
          background-color: #b3ff00;
        }
      }
    }
  }

  section.let-us-call {
    background: #1631b5;
    color: white;
    padding: 23px 20px 50px;
    position: relative;
    z-index: 3;
    .form-label {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 36px;
      flex-direction: column;
      p:nth-child(1) {
        font-weight: 200;
      }
      p:nth-child(2) {
        font-weight: 700;
      }
    }
    form {
      width: 100%;
      padding: 20px;
      max-width: 500px;
    }
    button {
      position: relative;
      &.sending {
        background-color: white;
        color: $mainBlue;
      }
      span.sent {
        background-color: $mainBlue;
      }
      span.error {
        background-color: red;
      }
    }

    &.light {
      background: white;
      color: #1631b5;
      padding: 50px 0 60px;
      position: relative;
      z-index: 3;
      .form-label {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        flex-direction: column;
        p:nth-child(1) {
          font-weight: 200;
        }
        p:nth-child(2) {
          font-weight: 700;
        }
      }
      form {
        width: 100%;
        padding: 20px;
        label {
          color: $mainBlue;
          font-weight: 300;
        }
        input {
          border: 1px solid #cbcdda;
          font-weight: 400;
          color: #182988;
        }
        button {
          color: white;
          background-color: $mainBlue;
        }
      }
    }
    @media screen and (max-width: 767.2px) {
      .form-label {
        font-size: 25px;
      }
      form {
        padding: 10px;
        margin: 0 auto;
      }
      &.light {
        .form-label {
          font-size: 25px;
        }
        form {
          padding: 10px;
        }
      }
    }
  }

  section.we-are-here {
    position: relative;
    z-index: 5;
    padding: 62px 0;
    .banner-label {
      font-size: 35px;
      font-weight: 600;
      color: #072098;
      display: flex;
      flex-direction: column;
      text-align: left;
      justify-content: center;
      height: 100%;
      p:nth-child(1) {
        font-weight: 200;
        font-size: 30px;
      }
      @media screen and (max-width: 767.2px) {
        align-items: center;
        margin-top: 20px;
      }
    }

    .map {
      height: 250px;
      width: 250px;
      border-radius: 26px;
      overflow: hidden;
      border: 10px dotted #b3ff00;
      padding: 10px;
      box-sizing: content-box;
      display: flex;
      align-self: center;
      margin: 0 auto;
    }
  }

  section.address {
    position: relative;
    background-color: $mainBlue;
    color: white;
    padding: 62px 0;
    z-index: 5;
    .section-header {
      h1,h2,h3 {
        color: white;
      }
    }
    .contacts-details {
      padding: 20px 0;
      text-align: center;
      table {
        text-align: center;
        width: 600px;
        margin: 0 auto;
        tr.contacts-item {
          position: relative;
          margin: 10px 0;
          td.contact-icon {
            width: 120px;
            padding: 15px;
            text-align: right;
            img {
              height: 40px;
              width: 40px;
              position: relative;
              display: inline-block;
            }
          }
          td.contact-value {
            width: 270px;
            text-align: left;
            span {
              width: auto;
              text-align: left;
              font-size: 16px;
            }
          }
        }
      }
    }
    @media screen and (max-width: 767.2px) {
      .contacts-details {
        padding: 20px 0;
        table {
          width: 300px;
          tr.contacts-item {
            position: relative;
            margin: 10px 0;
            td.contact-icon {
              width: 120px;
              padding: 15px;
              text-align: center;
              img {
                height: 32px;
                width: 32px;
                position: relative;
                display: inline-block;
              }
            }
            td.contact-value {
              width: 270px;
              text-align: left;
              span {
                width: auto;
                text-align: left;
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

header.header {
  position: absolute;
  height: 80px;
  width: 100%;
  background: linear-gradient(45deg, #021782, $mainBlue);
  z-index: 6;
  transition: all 0.4s ease;
  .head-line {
    //display: none;
    position: absolute;
    text-align: center;
    font-size: 0;
    width: 100%;
    ul.top-links {
      position: fixed;
    right: 0;
      top: calc(50vh - 119px);
      background: linear-gradient(271deg, #227d00, #42cc0d);
      border-radius: 10px 0 0 10px;
      text-align: center;
      cursor: pointer;
      display: inline-block;
      padding: 3px 3px 8px;
      li {
        position: relative;
        display: block;
        padding: 10px 3px;
        line-height: 1;

        a {
          display: inline-block;
          img {
            position: relative;
            top: 0;
            left: 0;
            width: 26px;
            transition: all 0.2s ease;
            &:hover {
              position: relative;
              top: 3px;
              left: 0px;
              width: 28px;
            }
          }
        }
      }
    }
  }
  .menu-line {
    position: relative;
    display: flex;
    padding: 20px 0;
    align-items: center;
    justify-content: space-between;
    .logo {
      display: inline-block;
      height: 45px;
      margin: 0 30px;
      /* padding: 3px 27px 10px; */
      position: relative;
      top: -1px;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    ul.menu {
      text-wrap: nowrap;
      font-size: 15px;
      padding: 0px 30px;
      li {
        position: relative;
        display: inline-block;
        margin: 0;
        a {
          text-decoration: none;
          color: white;
          font-weight: 400;
          padding: 9px 14px;
          display: block;
          &:visited {
            color: white;
          }
        }
        &.call-me {
          border-radius: 0px;
          color: #ffffff;
          font-size: 14px;
          margin: 0 20px;
          z-index: 1;
          padding: 0;
          background-color: transparent;
          border: 1px solid transparent;
          transition: all 0.3s ease;
          border-left: 1px solid;
          a{
            padding: 6px 13px 5px 3px;
          }
          &:hover {
            background-color: #42cc0d;
            border: 1px solid #42cc0d;
            a {
              color: white;
            }
          }
          img{
            width: 23px;
            position: relative;
            margin: 0 10px 0 11px;
            top: -2px;
          }
          span.button-text{
            position: relative;
            top: 1px;
          }
        }
      }
    }

    .mobile-menu {
      position: relative;
      display: none;
      color: white;
      font-size: 20px;
      margin: 0 31px;
      img {
        cursor: pointer;
        height: 18px;
        position: relative;
        top: -2px;
      }
    }

    @media screen and (max-width: 990px) {
      ul.menu {
        li {
          margin: 0px;
        }
      }
    }

    @media screen and (max-width: 767.2px) {
      height: 75px;
      &.dark {
        background: none;
        background-color: transparent;
        color: white;
      }
      .logo {
        display: inline-block;
        height: 45px;
        margin: 0 15px;
        position: relative;
        top: -1px;
        a{
          img{
            width:90%;
          }
        }
      }

      ul.menu {
        display: none;
        position: absolute;
        right: 0;
        top: 0;
        height: 100vh;
        width: 0;
        overflow: hidden;
        background: linear-gradient(45deg, #021782, #1631b5);
        padding-top: 80px;
        text-align: center;
        opacity: 0.95;
        li {
          display: block;
          margin: 20px;
          a {
            text-decoration: none;
            color: white;
            font-weight: 300;
            &:visited {
              color: white;
            }
          }
        }
        &.open {
          display: block;
          width: 100vw;
        }
      }
      .mobile-menu {
        display: block;
      margin: 0 17px;
      }
    }
  }

  &.dark {
    background: none;
    background-color: transparent;
    color: white;
  }
}

footer.footer {
  position: relative;
  background-color: white;
  color: #050c2d;
  width: 100%;
  z-index: 2;
  font-size: 14px;
  font-weight: 400;
  padding: 42px 0;
  &.dark {
    background: linear-gradient(45deg, $mainBlue, #021782);
    color: white;
  }

  .logo {
    height: 100%;
    width: 100%;
    position: relative;
    padding: 7px 7px 10px;
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      height: 55px;
      object-fit: contain;
    }
  }

  ul.courses-menu,
  ul.services-menu {
    li {
      display: block;
      margin: 20px;
      font-weight: 300;
      text-align: left;
      &.list-header {
        font-weight: 400;
      }
      a {
        text-decoration: none;
        color: inherit;
        &:visited {
          color: inherit;
        }
      }
    }
    @media screen and (max-width: 767.2px) {
      li {
        text-align: center;
      }
    }
  }
}
